document.addEventListener("DOMContentLoaded", function() {
  var controller = document.body.dataset.controller;
  var action = document.body.dataset.action;

  if (controller == "links" && (action == "edit" || action == "new")){


  // Changing the visiblity of the product or link destination 

  let dropdown = document.querySelector('select[name="link[link_type]"]');
  let productForm = document.getElementById('existing-product-form');
  let urlInput = document.getElementById('url-input-field');

  // Function to show or hide elements based on dropdown value
  let adjustForm = function() {
    if (dropdown.value === 'product') {
      productForm.style.display = 'block';
      urlInput.style.display = 'none';
    } else {
      productForm.style.display = 'none';
      urlInput.style.display = 'block';
    }
  }
  
  // Event listener for dropdown changes
  dropdown.addEventListener('change', adjustForm);

  // Call function to adjust form on page load
  adjustForm();

  // Media Presentation Preference

  let mediaDropdown = document.querySelector('select[name="link[media_presentation_preference]"]');
  let imageInputField = document.getElementById('image-input-field');
  let videoInputField = document.getElementById('video-input-field');

  let adjustMediaForm = function() {
    switch (mediaDropdown.value) {
      case 'none':
        imageInputField.classList.add("hidden");
        videoInputField.classList.add("hidden");
        break;
      case 'image':
        imageInputField.classList.remove("hidden");
        videoInputField.classList.add("hidden");
        break;
      case 'large_image':
        imageInputField.classList.remove("hidden"); 
        videoInputField.classList.add("hidden");
        break;
      case 'large_image_bottom':
        imageInputField.classList.remove("hidden"); 
        videoInputField.classList.add("hidden");
        break;        
      case 'full_image':
        imageInputField.classList.remove("hidden");
        videoInputField.classList.add("hidden");
        break;
      case 'video':
        imageInputField.classList.add("hidden");
        videoInputField.classList.remove("hidden");
        break;
    }
  }
  
  // Event listener for media dropdown changes
  mediaDropdown.addEventListener('change', adjustMediaForm);

  // Call function to adjust media form on page load
  adjustMediaForm();

  }
});
