document.addEventListener("DOMContentLoaded", function() {
  var controller = document.body.dataset.controller;
  var action = document.body.dataset.action;

  if (controller == "products" && (action == "edit" || action == "new")){

    // Select pricing type form visiblity 

    const chargedRadios = document.querySelectorAll("[name='product[payment_type]']");
    const purchaseFormFields = document.querySelector(".purchase-form-fields");
    const subscriptionFormFields = document.querySelector(".subscription-form-fields");

    function toggleFormFields() {
      const selectedPaymentType = Array.from(chargedRadios).find(radio => radio.checked)?.value || "";

      if (selectedPaymentType === "purchase") {
        purchaseFormFields.classList.remove("hidden");
        // subscriptionFormFields.classList.add("hidden");
      } else if (selectedPaymentType === "subscription") {
        purchaseFormFields.classList.add("hidden");
        // subscriptionFormFields.classList.remove("hidden");
      } else {
        purchaseFormFields.classList.add("hidden");
        // subscriptionFormFields.classList.add("hidden");
      }
    }

    if (chargedRadios.length > 0) {
      chargedRadios.forEach(radio => radio.addEventListener("change", toggleFormFields));

      // Ensure the initial state matches the toggle's value when the page loads
      toggleFormFields();
    }
  }
});


