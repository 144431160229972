document.addEventListener("turbolinks:load", function() {
  var controller = document.body.dataset.controller;
  var action = document.body.dataset.action;

  if (controller == "contacts" && action == "edit") {
    // Code for the "edit" action of the "products" controller goes here

    // Screen width slider

    document.addEventListener('DOMContentLoaded', function() {
      const slider = document.querySelector('.theme-preview-slider');
      const previewWindow = document.querySelector('.theme-preview-window');

      slider.addEventListener('input', function() {
        previewWindow.style.width = this.value + 'px';
      });
    });


    //  Toggle buttons CSS change

    document.addEventListener('DOMContentLoaded', () => {
      const buttons = document.querySelectorAll('.isolate button');
      const input = document.getElementById('page_border_style_input');
      const savedValue = input.value;

      buttons.forEach(button => {
        button.addEventListener('click', () => {
          buttons.forEach(btn => {
            btn.classList.remove('bg-blue-600', 'text-black');
          });
          button.classList.add('bg-blue-600', 'text-blue-600');
          input.value = button.dataset.value;
        });

        // Set saved value on page load
        if (button.dataset.value === savedValue) {
          button.classList.add('bg-blue-600', 'text-blue-600');
        }
      });
    });


    document.addEventListener('DOMContentLoaded', () => {
      const buttons = document.querySelectorAll('.isolate .toggle-btn');
      const input = document.getElementById('page_description_alignment_input');
      const savedValue = input.value;

      buttons.forEach(button => {
        button.addEventListener('click', () => {
          buttons.forEach(btn => {
            btn.classList.remove('bg-blue-600', 'text-white');
          });
          button.classList.add('bg-blue-600', 'text-white');
          input.value = button.dataset.value;
        });

        // Set saved value on page load
        if (button.dataset.value === savedValue) {
          button.classList.add('bg-blue-600', 'text-white');
        }
      });
    });


    // Making sure the page description alignment is submitted

    document.addEventListener('DOMContentLoaded', function() {
      const toggleButtons = document.querySelectorAll('.toggle-btn');
      const hiddenInput = document.getElementById('page_description_alignment_input');

      toggleButtons.forEach(function(button) {
        button.addEventListener('click', function() {
          const selectedValue = button.getAttribute('data-value');
          hiddenInput.value = selectedValue;
        });
      });
    });


    // Making sure the page page_title_alignment alignment is submitted

    document.addEventListener('DOMContentLoaded', function() {
      const toggleButtons = document.querySelectorAll('.toggle-btn');
      const hiddenInput = document.getElementById('page_title_alignment_input');

      toggleButtons.forEach(function(button) {
        button.addEventListener('click', function() {
          const selectedValue = button.getAttribute('data-value');
          hiddenInput.value = selectedValue;
        });
      });
    });


  }
});
