document.addEventListener('DOMContentLoaded', () => {
  var controller = document.body.dataset.controller;
  var action = document.body.dataset.action;

  const contactActivityModal = document.getElementById('contact-activity-modal');

  if (contactActivityModal) { // Check if modal exists on the page
    // Hide modal on outside click
    contactActivityModal.addEventListener('click', (event) => {
      if (event.target === contactActivityModal) {
        contactActivityModal.classList.add('hidden');
      }
    });
  }

  if (controller === "opportunities" || controller === "contacts") {
    const modalOpportunityIdInput = document.getElementById('modalOpportunityId');
    const contactIdField = document.querySelector('#contact_activity_contact_id');
    const contactActivityTriggers = document.querySelectorAll('.contact-activity-trigger');

    if (contactActivityTriggers.length > 0) {
      // Show modal on trigger button click
      contactActivityTriggers.forEach(trigger => {
        trigger.addEventListener('click', (event) => {
          const opportunityId = event.currentTarget.getAttribute('data-opportunity-id');
          const contactId = event.currentTarget.getAttribute('data-contact-id');

          if (modalOpportunityIdInput) {
            modalOpportunityIdInput.value = opportunityId;
          }

          if (contactIdField) {
            contactIdField.value = contactId;
          }

          if (contactActivityModal) {
            contactActivityModal.classList.remove('hidden');
          }
        });
      });
    }
  }
});

