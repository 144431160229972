import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: ".draggable",
      draggable: ".draggable",
      group: 'shared', // Allow dragging between lists with the same group name
      onEnd: this.end.bind(this)
    });
  }

  end(event) {
    console.log("Event Item:", event.item);
    // const url = event.item.getAttribute('data-sortable-update-url');
    const opportunityId = event.item.getAttribute('data-opportunity-id');
    const destinationStageId = event.to.getAttribute('data-droppable-stage-id-value');
    const url = `/opportunities/${opportunityId}/update_stage/${destinationStageId}`;
    console.log("Fetching URL:", url);
   

    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": document.querySelector("[name=csrf-token]").content
      }
    });
 

  // Trigger the modal after the fetch request
  // const modalTriggerButton = document.querySelector(`[data-modal-trigger="activity-modal"][data-opportunity-id="${opportunityId}"]`);
  //   if (modalTriggerButton) {
  //     modalTriggerButton.click();
  //   }
  }
 }