/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

// Make accessible for Electron and Mobile adapters
window.Rails = Rails
window.Turbo = Turbo

require("@rails/activestorage").start()
import "@rails/actiontext"

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"


// lnks
import "@nathanvda/cocoon"
import "./theme-preview"
import "./contact-page-modal"
import "./page"
import "./product"
import "./link"




// Jumpstart Pro & other Functionality
import "./src/**/*"
require("local-time").start()



// jquery does not export 'default' but defines window.$ and
// window.jQuery when loaded:
// - import 'jquery'; will not work
// - namespace does not matter here (jq)
// - no need to redefine it again w/ window.$ = jq.$
import * as jq from 'jquery';


// Define a variable to check in inlined HTML script
window.importmapScriptsLoaded = true;

// Start Rails UJS
Rails.start()